/*h1,h2,h3,h4,h5,h6{
  margin-top:1rem;
  margin-bottom:1rem;
  font-family: 'Heebo',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.navbar{
  background-image: url('./Imagenes/banner_ventana.jpg');
}

.navbar-dark .navbar-nav .nav-link{
  color:white;
}

#logo{
  background-image:url('./Imagenes/banner_logo.png');
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top:0;
  right:0;
  width:100%;
  height:100%;
}

poner todos los elementos del navbar en frente del logo
 #logo ~ *{
  z-index:2;
}

#navbar-nav{
   variable que se va a setear mediante javascript 
  left:var(--logoHeight);
  position:relative;
  min-height: 52px; 
} */

.aclaracion{
  color:navy;
  font-size: 0.85rem;
}

.flex-break{
  flex-basis:100%;
  height:0;
}

.cursor-pointer{
  cursor:pointer;
}

.llenar-espacio{
  height: calc(100vh - var(--logoHeight) - .5rem);
}

/* PARA PONER LABEL A LA IZQUIERDA DEL CHECKBOX */
div.custom-check-right {
  padding-right: 24px;
  padding-left: 0;
  /*margin-left: 16px;*/
  margin-right: 0;
}
div.custom-check-right .custom-control-label::before,
div.custom-check-right .custom-control-label::after{
  right: -1.5rem;
  left: initial;
}

/* GRILLA */
table{
  max-width:100%;
}

table.clamp{
    table-layout: fixed;
}

table.clamp td:not(.control),table.clamp th{ 
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

table.table > tbody > tr.selected{
  background-color: aliceblue;
}

table.middle-vertical-align th,table.middle-vertical-align td{
  vertical-align:middle;
}

table.tablaConSeleccion tr > th:first-child,table.tablaConSeleccion tr > td:first-child{
  width:2em;
}


/* LOADER */ 

.loader-container{
  position:relative;
  left:50%;
  height:50px;
}

.loader-inner > div{
  background-color: blue !important;
}
/* PARA PONER GRILLAS QUE CUBRAN TODA LA PANTALLA*/
.full-width{
 width: 100vw;
position: relative;
left: 50%;
right: 50%;
margin-left: -50vw;
margin-right: -50vw;
padding-left: 15px;
}

